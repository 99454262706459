import { useQuery } from 'react-query';

import axios from 'axios';

const CONTAINER_TYPE_NAMES = {
  GRN: 'ειδικό δοχείο του πράσινου ΑΤΜ',
  PET: 'πλαστικό μπουκάλι',
};

export const useContainers = () =>
  useQuery('containers', () =>
    axios.get('/api/containers/').then(({ data }) =>
      data.map((c) => ({
        ...c,
        container_name: CONTAINER_TYPE_NAMES[c.container_type],
        container_size_name:
          c.container_size < 1000
            ? `${c.container_size} ml`
            : `${(c.container_size / 1000).toFixed()} l`,
      }))
    )
  );
