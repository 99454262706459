import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Helmet } from 'react-helmet';

import { Header, Container, Segment, Divider, Accordion, List, Table, Icon } from 'semantic-ui-react';

import { useContainers } from '../queries/containers';

import axios from 'axios';


const colorStyle = { fontWeight: 'bold', color : '#019f46' };

export default function FaqPage({ registerUrl }) {

  const [activeIndexes, setActiveIndexes] = useState([]);

  const location = useLocation();

  const { data: containers } = useContainers();

  const {data: activeMunicipalities} = useQuery('activeMunicipalities',
    () => axios.get('/api/municipalities_infos/').then(({data}) => data));

  const panels = useMemo(() => [
    {
      key: 'whatis',
      title: {id: 'whatis', content: 'Ποια είναι τα τηγανέλαια;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Όλα τα λάδια που χρησιμοποιούμε στο σπίτι μας για μαγείρεμα, ή τηγάνισμα όπως ελαιόλαδο, σπορέλαια (ηλιέλαιο, σογιέλαιο, αραβοσιτέλαιο, βαμβακέλαιο, φοινικέλαιο) και πυρηνέλαιο. Ακόμα και τα παλιά ταγκισμένα λάδια μπορούν να ανακυκλωθούν.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'collect',
      title: {id: 'collect', content: 'Πώς συλλέγουμε τα τηγανέλαια;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <List items={[
              'Αφήνουμε το χρησιμοποιημένο μαγειρικό λάδι να κρυώσει.',
              'Αν χρειάζεται το περνάμε από ένα σουρωτήρι ή ένα φίλτρο για να το απαλλάξουμε από τα υπολείμματα των τροφών.',
              'Το μεταγγίζουμε με χωνί σε πλαστικό δοχείο (μπουκάλι λαδιού, νερού ή αναψυκτικού) ή στο ειδικό μπουκάλι ανακύκλωσης τηγανέλαιων στα ΑΤΜ.',
            ]} />

            <List style={colorStyle} items={[
              'ΣΗΜΑΝΤΙΚΟ: Δεν χρησιμοποιούμε γυάλινα δοχεία και προσέχουμε να μην αναμειχθεί με νερό ή άλλο υγρό.',
              'Μόλις γεμίσει το δοχείο το κλείνουμε καλά και το μεταφέρουμε στα ειδικά σημεία (ΑΤΜ ή πορτοκαλί κάδο) συλλογής χωρίς να το αδειάσουμε.',
            ]} />
          </Segment>
        )
      }
    },
    {
      key: 'donts',
      title: {id: 'donts', content: 'Τι δεν βάζουμε στα μπουκάλια των τηγανέλαιων;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Τα λάδια που περιέχουν σάλτσες, νερό ή υπολείμματα τροφών (όπως από σαλάτες, φαγητά κλπ.) δεν ανακυκλώνονται, όπως επίσης και τα λάδια αυτοκινήτου.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'deposit',
      title: {id: 'deposit', content: 'Πού μπορώ να αφήσω τα μπουκάλια μου με τα τηγανέλαια;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>Στα ειδικά σημεία ανακύκλωσης τηγανέλαιων. Αυτά είναι:</p>
            
            <List bulleted items={[
              'τα ΑΤΜ ανακύκλωσης τηγανέλαιων και',
              'ο πορτοκαλί κάδος της ΔΙΑΔΥΜΑ.',
            ]}/>

            <Link to="/points">Δείτε εδώ σε χάρτη τα ειδικά σημεία ανακύκλωσης τηγανέλαιων.</Link>
          </Segment>
        )
      }
    },
    {
      key: 'atms',
      title: {id: 'atms', content: 'Πώς λειτουργούν τα ΑΤΜ ανακύκλωσης τηγανέλαιων;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <List>
              <List.Item>Τοποθετούμε τα τηγανέλαια στο ειδικό μπουκάλι των ΑΤΜ με τη βοήθεια χωνιού.</List.Item>
              <List.Item>Αφού κλείσουμε καλά το καπάκι, κολλάμε πάνω στο <strong>κάπακι</strong>, το αυτοκόλλητο με τον ατομικό κωδικό barcode που έχουμε προμηθευτεί από τη ΔΙΑΔΥΜΑ</List.Item>
              <List.Item>Τοποθετούμε το ειδικό μπουκάλι στην υποδοχή των ΑΤΜ.</List.Item>
              <List.Item>Το ΑΤΜ βγάζει αυτόματα ένα άδειο, καθαρό μπουκάλι για την επόμενη χρήση.</List.Item>
            </List>
          </Segment>
        )
      }
    },
    {
      key: 'where',
      title: {id: 'where', content: 'Από που μπορώ να προμηθευτώ το ειδικό δοχείο συλλογής τηγανέλαιων για τα ΑΤΜ και τα ειδικά barcode;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>Προμηθευτείτε το πράσινο δοχείο συλλογής τηγανέλαιων:</p>

            <List>
              <List.Item>
                <Icon name="map pin" />
                <List.Content>Από τη <a href="https://diadyma.gr">ΔΙΑΔΥΜΑ</a>: <a href="tel:+30 246 104 5531">2461045531</a></List.Content>
              </List.Item>
              <List.Item>
                <Icon name="map pin" />
                <List.Content>Από το <a href="https://clube.gr/">Cluster of Bioeconomy &amp; Environment of Western Macedonia (Clube)</a>: <a href="tel:+30 246 105 6706">2461056706</a></List.Content>
              </List.Item>
              <List.Item>
                <Icon name="map pin" />
                <List.Content>Από το Δήμο σας τηλεφωνώντας στην υπηρεσία καθαριότητας του Δήμου σας:</List.Content>
                <List.List>
                  {activeMunicipalities?.map(({ municipality, url, tel }) =>
                    <List.Item>
                      <Icon  />
                      <List.Content><a href={url}>{municipality}</a>: <a href={`tel:+30${tel}`}>{tel}</a></List.Content>
                    </List.Item>
                  )}
                </List.List>
              </List.Item>
              
            </List>

            <List style={colorStyle} items={[
              'ΣΗΜΕΙΩΣΗ: το ειδικό δοχείο θα το παραλάβετε την πρώτη φορά για να κάνετε χρήση του μηχανήματος, το οποίο θα σας δώσει αυτόματα ένα καθαρό καινούριο μπουκάλι για το επόμενο γέμισμα.'
            ]} />
            
          </Segment>
        )
      }
    },
    {
      key: 'bottles',
      title: {id: 'bottles', content: 'Ποιες συσκευασίες τοποθετώ στον πορτοκαλί κάδο ανακύκλωσης τηγανέλαιων;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Οποιαδήποτε πλαστική συσκευασία που προηγουμένως είχε λάδι, νερό ή αναψυκτικό. (Προσέχουμε ιδιαίτερα να μην έχει οποιοδήποτε άλλο υγρό, εκτός από λάδι)
            </p>

            <p>Αποδεκτές χωρητικότητες: 0.5, 1, 1.5 ή 2 λίτρα</p>
          </Segment>
        )
      }
    },
    {
      key: 'winwin',
      title: {id: 'winwin', content: 'Τι κερδίζω από την ανακύκλωση τηγανέλαιων;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Με κάθε μπουκάλι τηγανέλαιων που ανακυκλώνεται, ο πολίτης που είναι εγγεγραμμένος χρήστης στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ κερδίζει πόντους οι οποίοι μπορούν να εξαργυρωθούν σε συμβεβλημένα καταστήματα.
            </p>
            
            <p>
              Πληροφορίες για το σύστημα επιβράβευσης στη σελίδα <a href="https://rewards.diadyma.gr">https://rewards.diadyma.gr</a>.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'rewards',
      title: {id: 'rewards', content: 'Πως λειτουργεί το σύστημα καταγραφής πόντων σύμφωνα με τα τηγανέλαια που δίνω για ανακύκλωση;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Ανάλογα με το βάρος των τηγανέλαιων ο εγγεγραμμένος χρήστης κερδίζει τους αντίστοιχους πόντους:
            </p>

            <Table basic compact collapsing unstackable textAlign="right">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan={2}>
                      Τύπος μπουκαλιού
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Πόντοι</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {containers?.map(
                    ({ id, container_name, container_size_name, points }) => (
                      <Table.Row key={id}>
                        <Table.Cell style={{ textTransform: 'capitalize' }}>
                          {container_name}
                        </Table.Cell>
                        <Table.Cell>{container_size_name}</Table.Cell>
                        <Table.Cell>→</Table.Cell>
                        <Table.Cell>{points} Πόντοι</Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
              </Table>

          </Segment>
        )
      }
    },
    {
      key: 'barcodes',
      title: {id: 'barcodes', content: 'Τι είναι τα ατομικά barcode χρήστη;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Τα ατομικά barcode χρήστη, είναι αυτοκόλλητα με μοναδικό κωδικό ανά εγγεγραμμένο χρήστη, τα οποία θα τοποθετούνται πάνω στην πλαστική συσκευασία πριν την ανακύκλωσή τους. Είναι απαραίτητα για την ταυτοποίηση του χρήστη από το σύστημα της ΔΙΑΔΥΜΑ, έτσι ώστε να περαστούν οι αντίστοιχοι πόντοι στο λογαριασμό του χρήστη. Οι πόντοι θα περνιούνται στο σύστημα από υπαλλήλους της ΔΙΑΔΥΜΑ. Ενδεχομένως να αργούν να περαστούν μερικές μέρες.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'why',
      title: {id: 'why', content: 'Γιατί να δημιουργήσω λογαριασμό χρήστη στις ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Η ΔΙΑΔΥΜΑ ακολουθώντας τα ευρωπαϊκά πρότυπα εντάσσει στη φιλοσοφία της την ανταποδοτική διαχείριση αποβλήτων. Για το λόγο αυτό οι πολίτες της Δυτικής Μακεδονίας που ανακυκλώνουν τηγανέλαια ή συμμετέχουν στην επαναχρησιμοποίηση μέσω των Κέντρων Δημιουργικής Επαναχρησιμοποίησης Υλικών θα επιβραβεύονται. Θα έχουν τη δυνατότητα συλλογής πόντων τους οποίους και θα εξαργυρώνουν σε συμβεβλημένα καταστήματα. Για περισσότερες πληροφορίες επισκεφθείτε τη σελίδα <a href="https://rewards.diadyma.gr">https://rewards.diadyma.gr</a>.
            </p>

            <p>
              Οι ψηφιακές υπηρεσίες της ΔΙΑΔΥΜΑ είναι ένας κοινός τόπος εγγραφής σε όλες τις εναλλακτικές, καλές πρακτικές διαχείρισης αποβλήτων που εφαρμόζει η ΔΙΑΔΥΜΑ, όπως η <a href="https://reuse.diadyma.gr/">επαναχρησιμοποίηση</a> και η ανακύκλωση τηγανέλαιων. Μπορείτε <a href={registerUrl}>να εγγραφείτε εδώ</a> και να απολαύσετε μοναδικά προνόμια επιβράβευσης.
            </p>
          </Segment>
        )
      }
    },
    {
      key: 'diadyma',
      title: {id: 'diadyma', content: 'Ποια είναι η ΔΙΑΔΥΜΑ ΑΕ;'},
      content: {
        content: (
          <Segment basic padded size="large">
            <p>
              Η Ανώνυμη Εταιρεία Διαχείρισης Απορριμμάτων Δυτικής Μακεδονίας (ΔΙΑΔΥΜΑ ΑΕ) ιδρύθηκε το 1998 και είναι ο Φορέας Διαχείρισης Απορριμμάτων (ΦοΔΣΑ) της Περιφέρεια Δυτικής Μακεδονίας.Έχοντας ως προτεραιότητα "Τον Πολίτη στο Περιβάλλον του", αξιοποιήθηκαν οι διαθέσιμοι πόροι και τα πλεονεκτήματα της περιοχής, σχεδιάστηκε ένα ολοκληρωμένο σύστημα διαχείρισης απορριμμάτων, ικανό να προσαρμόζεται στις ολοένα αυξανόμενες απαιτήσεις της περιβαλλοντικής νομοθεσίας. Η επίτευξη των στόχων και των υποχρεώσεων διαδέχονται η μία την άλλη και κατατάσσουν την περιφέρειά μας μεταξύ των "Καλών Πρακτικών" στην διαχείριση των στερεών αποβλήτων, σε επίπεδο Ευρωπαϊκής Ένωσης.
            </p>
          </Segment>
        )
      }
    },
  ], [registerUrl, containers, activeMunicipalities]);

  useEffect(() => {
    let id = location.hash.substring(1);
    let activeIndex = panels.findIndex((panel) => panel.key === id);
    setActiveIndexes(activeIndex !== -1 ? [activeIndex] : []);
    let activeAccordion  = document.getElementById(id);
    activeAccordion?.scrollIntoView();
  }, [location, panels])

  const handleTitleClick = (e, {index}) =>
    setActiveIndexes((activeIndexes) => {
      if (activeIndexes.includes(index)){
        if(activeIndexes.length === 1) {
          window.history.pushState({},'',`${location.pathname}`)
        }
        return activeIndexes.filter((i) => i !== index);
      } else {
        window.history.pushState({},'',`${location.pathname}#${panels[index].key}`)
        return [...activeIndexes, index];
      }
    });
  
  const pageTitle = 'Συχνές ερωτήσεις';
  return (<>
    <Helmet title={pageTitle} />
    <Container>
      <Header as="h1" textAlign="center" className="colored atmgreen" content={pageTitle} />
      <Divider className="orange" />
    </Container>

    <Segment basic attached className="colored lightgreen">
      <Container>
        <Divider hidden />
        <Accordion exclusive={false} activeIndex={activeIndexes}  className="green" panels={panels} onTitleClick={handleTitleClick} />

        <Divider section hidden />
        <Divider className="fat" />
        <Divider hidden />
      </Container>
    </Segment>
  </>);
}
